import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

/*
 * Based on https://css-tricks.com/can-get-pretty-far-making-slider-just-html-css/
 */

const PostList = (props) => {

  const data = props.data;
  const { currentPage, numPages } = props.pageContext;

//  const [sortedBy, setSortedBy] = useState("date-desc");


  if (data == null || typeof data === "undefined") {
    return null;
  } else {
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = (currentPage - 1) === 1 ? "../" : "../" + (currentPage - 1).toString();
    const nextPage = "/posts/" + (currentPage + 1).toString();

    const pagination = (
      <div className="pagination">
        {!isFirst && (
          <Link to={prevPage} rel="prev">Previous Page</Link>
        )}
        {isFirst && <div></div>}
        {!isLast && (
          <Link to={nextPage} rel="next">Next Page</Link>
        )}
      </div>
    );
  
    const pageLinks = (
      <div className="page-links">
        {[...Array(numPages)].map((e, i) => {
          const linkNum = i === 0 ? "" : (i + 1).toString();
          return (
            <Link key={'page- ' + i} to={"/posts/" + linkNum}>
              {i+1}
            </Link>
          )
        })}
      </div>
    );
  
    return (
      <div>
        {props.pagination && pagination}
        <div className="posts-container">
          {data.map(({ node }, index) => (
            <div className="post-item" key={index}>
              <Link to={node.fields.slug}>
                <GatsbyImage image={getImage(node.fields.thumbnail)} className="post-thumbnail" alt={node.frontmatter.title + ' from ' + node.frontmatter.source}/> 
              </Link>
              <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
              <div>{node.frontmatter.date}</div>
            </div>
          ))}
        </div>
        {props.pagination && pagination}
        {props.pageLinks && pageLinks}
      </div>
    );
  }
}

PostList.propTypes = {
  data: PropTypes.array,
  pageContext: PropTypes.object,
  pagination: PropTypes.bool,
  pageLinks: PropTypes.bool
}

PostList.defaultProps = {
  data: [],
  pageContext: {},
  pagination: true,
  pageLinks: true
}

export default PostList