import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostList from "../components/post_list"
import { getImage } from "gatsby-plugin-image"

export default function Posts(props) {
  const first_video = props.data.allMdx.edges[0].node;
  
  return (
    <Layout>
      <Seo title="All Posts" 
        image={getImage(first_video.fields.thumbnail)}
      />
      <div>
        <h1>All Posts</h1>
        <PostList data={props.data.allMdx.edges} pageContext={props.pageContext} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH)
              }
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM D, Y")
          }
        }
      }
    }
  }
`
